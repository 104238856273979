
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

import axios from "axios";
export interface SmsInfo {
    smsUrl: SmsUrlInfo;
    smsList:ISmsList;
    errors: Array<string>;
    
}

export interface SmsUrlInfo {

    getSmsListUrl:string;
}
export interface ISmsList {
    id: number;
    customer_id: number;
    lead_id?: any;
    content?: any;
    status: number;
    is_promo?: any;
    created_at: string;
    updated_at?: any;
}

@Module
export default class SmsModule extends VuexModule implements SmsInfo {
    smsUrl = {} as SmsUrlInfo;
    smsList = {} as ISmsList;
    errors = [];

    
    @Action
    [Actions.GET_SMS_LIST](payload) {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.smsUrl.getSmsListUrl = '/skye/sms/list-sms';
            ApiService.post(this.smsUrl.getSmsListUrl, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_SMS_LIST, data.data.sms);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_SMS_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    
    @Mutation
    [Mutations.SET_SMS_ERROR](error) {
        const errors = [] as any;
        for (const key in error) {

            errors.push(error[key][0]);

        }
        this.errors = errors;
    }
    @Mutation
    [Mutations.SET_SMS_LIST](data) {
        this.smsList = data;
    }
   
    
    get getCustomerSmsList(): ISmsList {
        return this.smsList;
    }
}

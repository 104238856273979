/* eslint-disable no-useless-escape */
import { useRoleBasedConditions } from '@/store/composable/User'

const { salesAgentRequired } = useRoleBasedConditions()

class Validations {
    public static email_taken = false;
    public static email_not_match = false;
    public static phone_not_match = false;
    public static claimed_insurance = false
    public static is_lost_lead_reason = false
    public static task_notes = false
    public static promo_validation = false
    public static promo_error = '';
    public static is_doc_limit = false
    public static is_reference_no_exist = false
    public static is_phone_number = false
    public static is_password_used = false
    public static user_name_not_found = false;
    public static unauthorized = false;
    public static user_name_not_active = false;
    public static code_not_valid = false;
    public static code_expired = false;
    public static is_customer_bought_from = false
    public static is_customer_bought_from_message = ''
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter email'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_taken) {
            callback(new Error('Email address aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static confirm_email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter confirm email'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_not_match) {
            callback(new Error('Email address and confirm email not match'));
        }
        else {
            callback();
        }
    }

    public static password(rule: any, value: any, callback: any, is_edit = false) {    
        if(!value && !is_edit) {
            callback(new Error('Please enter password'));
          } else if(value.length < 8 && !is_edit) {
            callback(new Error('Password minimum of 8 characters'));
          } else {
            callback();
          }
    }

    public static passwordStrong(rule: any, value: any, callback: any) {
        const lengthRequirement = /.{18,}/ // At least 18 characters
        const uppercaseRequirement = /[A-Z]/ // At least one uppercase letter
        const lowercaseRequirement = /[a-z]/ // At least one lowercase letter
        const digitRequirement = /\d/ // At least one digit
        const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/ // At least one special character
    
        // Check each requirement
        const isLengthValid = lengthRequirement.test(value)
        const hasUppercase = uppercaseRequirement.test(value)
        const hasLowercase = lowercaseRequirement.test(value)
        const hasDigit = digitRequirement.test(value)
        const hasSpecialChar = specialCharRequirement.test(value)

        if(!value) {
            callback(new Error('Password is required'))
        //   } else if(value.length < 12) {
        //     callback(new Error('Password minimum of 12 characters'))
          } else if (value.length < 15 && !isLengthValid || !hasUppercase || !hasLowercase || !hasDigit || !hasSpecialChar) {
            callback(new Error('Password is too weak'))
          } else if (value.toLowerCase().includes('pass') || value.toLowerCase().includes('123') || value.toLowerCase().includes('p@ss')) {
            callback(new Error('Password must not contain a word pass or number 123'))
          } else if(Validations.is_password_used) {
            callback(new Error('Password already used'))
          } else {
            callback()
          }
    }

    public static username(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Username required'))
        } else if(Validations.user_name_not_found) {
            callback(new Error('Username not found'))
        } else if(Validations.unauthorized) {
            callback(new Error('Unauthorized access'))
        } else if(Validations.user_name_not_active) {
            callback(new Error('Username not active.'))
        } else {
            callback()
        }
    }

    public static confirm_password(rule: any, value: any, callback: any, password: string) {
        if(!value) {
            callback(new Error('Confirm password is required'))
          } else if(value.length < 18) {
            callback(new Error('Confirm password minimum of 18 characters '))
          } else if (value != password) {
            callback(new Error('Password and confirm password not match'))
          } else {
            callback()
          }
    }

    public static code(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Enter code'))
        } else if(Validations.code_not_valid) {
            callback(new Error('Code is not valid'))
        } else if(Validations.code_expired) {
            callback(new Error('Code already expired'))
        } else {
            callback()
        }
    }

    public static names(rule: any, value: any, callback: any) {
        const isvalidtext = /^[a-zA-Zء-ي\s'-]+$/;
        const singleCount = value ? value.match(/[']/g) : false;
        const minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if(singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if(minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if(!value) {
            callback(new Error('Please enter full name'));
        } else if(!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker) {
            callback(new Error(`Only character a-z, ء-ي, - and ' allowed`))
        } else {
            callback();
        }
    }

    public static other_contact_info(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter contact information'));
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter phone number'));
        } else if(value.length < 8 || value.length > 10) {
            callback(new Error('Phone number must not be less than 8 digits'));
        } else if(Validations.is_phone_number) {
            callback(new Error('Phone number already exist!'));
        }
         else {
            callback();
        }
    }

    public static confirm_phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter confirm phone number'));
        } else if(value.length < 8 || value.length > 10) {
            callback(new Error('Phone number must not be less than 8 digits'));
        } else if(Validations.phone_not_match) {
            callback(new Error('Phone number and confirm phone number not match'));
        }
         else {
            callback();
        }
    }

    public static lead_source(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Lead source is required'));
        }  else {
            callback();
        }
    }

    public static dob(rule: any, value: any, callback: any) {
        
        if(!value) {
            callback(new Error('Please enter date of birth'));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the gender'));
        } else {
            callback();
        }
    }
    public static is_brand_new(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_policy_expired(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_claimed_insurance(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the nationality'));
        } else {
            callback();
        }
    }

    public static first_driving_license_country(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select country'));
        } else {
            callback();
        }
    }

    public static age_group(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Age group is required'));
        } else {
            callback();
        }
    }

    public static insured_city(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the visa issued emirate'));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter company name'));
        } else {
            callback();
        }
    }

    public static agent(rule: any, value: any, callback: any) {
        if(!value && salesAgentRequired) {
            callback(new Error('Please select sales agent'));
        } else {
            callback();
        }
    }

    public static notes(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter notes'));
        } else {
            callback();
        }
    }

    public static requested_to(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select an underwriter'));
        } else {
            callback();
        }
    }

    public static expected_insurance_start_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter expected insurance start date'));
        } else {
            callback();
        }
    }

    public static is_salary(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your salary'));
        } else {
            callback();
        }
    }

    public static is_self(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your insurance'));
        } else {
            callback();
        }
    }
    
    public static car_year(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select year'));
        } else {
            callback();
        }
    }
    
    public static manufacturer_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car make'));
        } else {
            callback();
        }
    }
    
    public static model_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car model'));
        } else {
            callback();
        }
    }
    
    public static trim_level_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car trim'));
        } else {
            callback();
        }
    }

    public static bike_trim(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select engine capacity'));
        } else {
            callback();
        }
    }
    public static car_value(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter value'));
        } else if(value && Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        } else {
            callback();
        }
    }
    public static registration_emirate(rule: any, value: any, callback: any) {
        if(['', null, undefined].includes(value)) {
            callback(new Error('Please select emirate'));
        } else {
            callback();
        }
        // callback();
    }
    public static first_registration_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select registration date'));
        } else {
            callback();
        }
    }
    public static is_new(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select car is brand new?'));
        } else {
            callback();
        }
    }
    public static gcc_specification(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select gcc specs & no modification'));
        } else {
            callback();
        }
    }
    public static personal_use(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select vehicle use'));
        } else {
            callback();
        }
    }
    public static third_party_liability(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select existing policy TPL'));
        } else {
            callback();
        }
    }
    
    public static policy_start_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy start date'));
        } else {
            callback();
        }
    }

    public static policy_end_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy end date'));
        } else {
            callback();
        }
    }

    public static policy_start_date_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static driving_experience(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static uae_driving_experience(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static no_of_claims(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please enter no of claims'));
        } else {
            callback();
        }
    }

    public static claims(rule: any, value: any, callback: any) {
        if(!value && Validations.claimed_insurance) {
            callback(new Error('Please enter no of claims'));
        } else {
            callback();
        }
    }

    public static no_claim_certificate(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select certificate'));
        } else {
            callback();
        }
    }

    public static driver_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please  enter driver name'));
        } else {
            callback();
        }
    }

    public static transfer_agent(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select agent'));
        } else {
            callback();
        }
    }

    public static transfer_underwriter(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select underwriter'));
        } else {
            callback();
        }
    }
    
    public static email_to(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter email to'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else {
            callback();
        }
    }
    
    public static subject(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter subject'));
        } 
        else {
            callback();
        }
    }
    
    public static body(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter body'));
        } 
        else {
            callback();
        }
    }
    
    public static policy_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy type'));
        } 
        else {
            callback();
        }
    }
    
    public static companies(rule: any, value: any, callback: any) {
        if(!value || value.length == 0) {
            callback(new Error('Please select companies'));
        } 
        else {
            callback();
        }
    }
    
    public static disposition(rule: any, value: any, callback: any) {
        if(!value || value.length == 0) {
            callback(new Error('Please select response from customer'));
        } 
        else {
            callback();
        }
    }
    
    public static lost_lead_reason(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.is_lost_lead_reason) {
            callback(new Error('Please select lost lead reason'));
        } 
        else {
            callback();
        }
    }
    
    public static due_date(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.is_lost_lead_reason) {
            callback(new Error('Please enter due date & time'));
        } 
        else {
            callback();
        }
    }
    
    public static task_note(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.task_notes) {
            callback(new Error('Please enter due date & time'));
        } 
        else {
            callback();
        }
    }
    
    public static car_lead_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select car leads'));
        } 
        else {
            callback();
        }
    }
    
    public static bai_car_insurance_policy_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select insurance plan'));
        } 
        else {
            callback();
        }
    }
    
    public static is_agency_repair(rule: any, value: any, callback: any) {
        if(value == null) {
            callback(new Error('Please select agency repair'));
        } 
        else {
            callback();
        }
    }
    
    public static original_price(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter policy price'));
        } else if(Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        }
        else {
            callback();
        }
    }
    
    public static sale_price(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter sale price'));
        } else if(Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        }
        else {
            callback();
        }
    }
    
    public static order_description(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter order description'));
        } 
        else {
            callback();
        }
    }
    
    public static payment_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select payment type'));
        } 
        else {
            callback();
        }
    }
    
    public static language(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select language'));
        } 
        else {
            callback();
        }
    }
    
    public static reference_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter reference no'));
        } else if(Validations.is_reference_no_exist) {
            callback(new Error('Reference no. already exist'))
        }else {
            callback();
        }
    }
    
    public static payment_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter payment date'));
        } 
        else {
            callback();
        }
    }
    
    public static invoice_doc(rule: any, value: any, callback: any, message: any = 'Please upload file for COD / Direct') {
        if(!value) {
            callback(new Error(message));
        }  else if(value && Validations.is_doc_limit) {
            callback(new Error('Document size exceed limit of 2mb'))
        } else {
            callback();
        }
    }
    
    public static intransact_policy_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select quote ref no for intransact payment type'));
        } else {
            callback();
        }
    }
    
    public static expiry_date_time(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter expiry date'));
        } 
        else {
            callback();
        }
    }
    
    public static is_discount_given(rule: any, value: any, callback: any) {
        if(value == null) {
            callback(new Error('Discount is given'));
        } 
        else {
            callback();
        }
    }

    public static customer_name(rule: any, value: any, callback: any) {
        console.log('dsds',value)
        if(!value) {
            callback(new Error('Please enter customer name'));
        } 
        else {
            callback();
        }
    }

    public static customer_name_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static emirates_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter emirates id'));
        } 
        else if(value.length < 18) {
            callback(new Error('Emirates ID must be equal to 18'))
        }
        else {
            callback();
        }
    }

    public static emirates_id_draft(rule: any, value: any, callback: any) {
        if(value && value.length < 18) {
            callback(new Error('Emirates ID must be equal to 18'))
        }
        else {
            callback();
        }
    }

    public static policy_number(rule: any, value: any, callback: any, option: any) {
        if(!value) {
            callback(new Error('Please enter policy number'));
        } else if(value.length < 10) {
            callback(new Error('Policy number must not be less than 10 characters'))
        }
        else {
            callback();
        }
    }

    public static policy_number_draft(rule: any, value: any, callback: any) {
        if(value && value.length < 10) {
            callback(new Error('Policy number must not be less than 10 characters'))
        }
        else {
            callback();
        }
    }

    public static chassis_number(rule: any, value: any, callback: any) {
        const numberContain = /[0-9]/;
        const lettersContain = /[A-Za-z]/;
        if(!value) {
            callback(new Error('Please enter chassis number'));
        } else if(!lettersContain.test(value) || !numberContain.test(value)) {
            callback(new Error('Chassis number must be alphanumeric'))
        }
        else {
            callback();
        }
    }

    public static chassis_number_draft(rule: any, value: any, callback: any) {
        const numberContain = /[0-9]/;
        const lettersContain = /[A-Za-z]/;
        if(value && (!lettersContain.test(value) || !numberContain.test(value))) {
            callback(new Error('Chassis number must be alphanumeric'))
        }
        else {
            callback();
        }
    }

    public static chassis_number_update_data(rule: any, value: any, callback: any) {
        const specialCharContain = /[!@#$%^&*()_+=\[\]{}|;:'",.<>\/?]/;
        if(!value) {
            callback(new Error('Please enter chassis number'));
        } else if (specialCharContain.test(value)) {
            callback(new Error('Chassis number must contain only character or numbers or a combination of both'));
        }
        else {
            callback();
        }
    }

    public static insurance_payment(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select insurance payment'));
        } 
        else {
            callback();
        }
    }

    public static insurance_payment_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static entity_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select entity type'));
        } 
        else {
            callback();
        }
    }

    public static cancellation_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select cancellation type'))
        } else {
            callback()
        }
    }

    public static cancellation_credit_amount(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Credit note'))
        }else if(Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        } else {
            callback()
        }
    }

    public static cancellation_credit_notes_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Credit note no.'))
        } else {
            callback()
        }
    }

    public static cancellation_debit_amount(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Debit note'))
        } else if(Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        }  else {
            callback()
        }
    }

    public static cancellation_debit_notes_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Debit note no.'))
        } else {
            callback()
        }
    }

    public static cancellation_endorsement_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Endorsement no.'))
        } else {
            callback()
        }
    }

    public static cancellation_reason(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select cancellation reason'))
        } else {
            callback()
        }
    }

    public static cancellation_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter cancellation notes'))
        } else {
            callback()
        }
    }

    public static cancellation_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter cancellation date on invoice'))
        } else {
            callback()
        }
    }

    public static underwriter_cancellation_notes(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter underwriter notes'))
        } else {
            callback()
        }
    }

    public static cancellation_status_id(rule: any, value: any, callback: any) {
        if(!value || value === 6) {
            callback(new Error('Please select status'))
        } else {
            callback()
        }
    }

    public static pay_to(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select pay to'))
        } else {
            callback()
        }
    }

    public static policy_status(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy status'))
        } else {
            callback()
        }
    }

    public static refund_account_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter account notes'))
        } else {
            callback()
        }
    }

    public static refund_status(rule: any, value: any, callback: any) {
        if(!value || value <= 1) {
            callback(new Error('Please select status'))
        } else {
            callback()
        }
    }

    public static refund_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select refund type'))
        } else {
            callback()
        }
    }

    public static refund_reason(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select refund reason'))
        } else {
            callback()
        }
    }

    public static refund_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter refund notes'))
        } else {
            callback()
        }
    }

    public static sum_insured(rule: any, value: any, callback: any) {
        if(value === null || value === undefined || value == '') {
            callback(new Error('Please enter sum insured'))
        } else if(Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        } else {
            callback()
        }
    }

    public static sum_insured_draft(rule: any, value: any, callback: any) {
        if(value && Number(value) >= 1000000000) {
            callback(new Error('Incorrect amount'))
        } else {
            callback();
        }
    }

    public static policy_required_doc(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Kindly upload the required documents before you finally submit it for policy completion.'))
        } else {
            callback()
        }
    }

    public static entity_type_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static policy_required_doc_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static promo_code(rule: any, value: any, callback: any) {
        if((value !== '') &&  Validations.promo_validation) {
            callback(new Error(Validations.promo_error));
        } else {
            callback();
        }
    }

    public static customer_bought_from(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.is_customer_bought_from) {
            callback(new Error(Validations.is_customer_bought_from_message));
        }  else {
            callback();
        }
    }
    
    public static official_email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter valid official email address'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Official Email address must be valid'));
        } 
        else if(Validations.email_taken) {
            callback(new Error('Official Email address aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static qlemail(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(value && !re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_taken) {
            callback(new Error('Email address aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static is_round_robin(rule: any, value: any, callback: any) {
        if(![1, 0].includes(value)) {
            callback(new Error('Please select in round robin'));
        } else {
            callback();
        }
    }

    public static team_leader(rule: any, value: any, callback: any) {

        if(!value) {
            callback(new Error('Please select team leader'));
        } else {
            callback();
        }
    }

    public static underwriters(rule: any, value: any, callback: any, is_underwriter = 0) {
        if(!value && !is_underwriter) {
            callback(new Error('Please select underwriter'));
        } else {
            callback();
        }
    }

    public static assign_lead(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select assgin leads of'));
        } else {
            callback();
        }
    }

    public static assign_language(rule: any, value: any, callback: any) { 
        if(!value || value.length == 0) {
            callback(new Error('Please select language'));
        } else {
            callback();
        }
    }

    public static misc_insurance_types(rule: any, value: any, callback: any) {  
        if(!value || value.length == 0) {
            callback(new Error('Please select other insurance type'));
        } else {
            callback();
        }
    }

    public static agents(rule: any, value: any, callback: any) { 
        if(!value || value.length == 0) {
            callback(new Error('Please select transfer agent'));
        } else {
            callback();
        }
    }

    public static user_status(rule: any, value: any, callback: any, role_id, agent_count) { 
        if(!value && role_id == 4 && agent_count > 0) {
            callback(new Error('The underwriter cannot be made inactive because the underwriter is assigned to active agents. Kindly assign new underwriter for these agents and then inactive this underwriter.'));
        } else {
            callback();
        }
    }

    public static role_id(rule: any, value: any, callback: any, is_underwriter = 0) {   
        if(!value) {
            callback(new Error('Please select role'));
        } else {
            callback();
        }
    }

    public static password_confirmation(rule: any, value: any, callback: any, password: any, is_edit = false) {  
        if(!value && !is_edit) {
            callback(new Error('Please enter confirm password'));
        } else if(value.length < 8 && !is_edit) {
            callback(new Error('Password minimum of 8 characters'));
        } else if(value !== password && !is_edit) {
            callback(new Error('Password and confirm password do not match'));
        } else {
            callback();
        }
    }

    public static agent_type(rule: any, value: any, callback: any) {
        if(![1, 2, 3].includes(value)) {
            callback(new Error('Please select in agent type'));
        }  else {
            callback();
        }
    }

    public static is_all_deals(rule: any, value: any, callback: any, agent_type: any, is_llr = 0) {
        if([2, 3].includes(agent_type)) {
            if(!is_llr) {
                callback(new Error('Please select lost lead'));
            } else {
                if(![0, 1].includes(value)) {
                    callback(new Error('Please select self or all renewal deals'));
                }  else {
                    callback();
                }
            }
        } else {
            callback();
        }
    }
    public static insurance_plan_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy plan'))
        } else {
            callback()
        }
    }



}
/**
 * create custom validations
 * @created vincent carabbacan
 */
export default Validations;
const USER_DETAILS = "user" as string;

/**
 * @description get token form localStorage
 */
export const getUser = (): string | null => {
  return window.localStorage.getItem(USER_DETAILS);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveUser = (user: string): void => { 
  window.localStorage.setItem(USER_DETAILS,user);
};

/**
 * @description remove token form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.clear();
};

export default { getUser, saveUser, destroyUser };

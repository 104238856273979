import router from "@/router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class TaskService{
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    TaskService.vueInstance = app;
  }

  public static getLeadTaskList(payload){
    store.dispatch(Actions.GET_LEAD_TASK_LIST,payload)
    .then((data) => {
        // 
        // router.push({ name: "customer-list" });
      })
    .catch((error) => {
        // 
      })
  }
  public static deleteTask(payload){
    store.dispatch(Actions.DELETE_LEAD_TASK,payload)
    .then((data) => {
        // 
        // router.push({ name: "customer-list" });
      })
    .catch((error) => {
        // 
      })
  }
  

}

export default TaskService;

import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";

/**
 * @description service to call HTTP request via Axios
 */
class AchievementService {

  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    AchievementService.vueInstance = app;
  }

  /**
   * @param payload 
   * @returns 
   */
  public static getSalesDashboardAchievements() {
    return new Promise<any>((resolve, reject) => {
      console.log('avx00');
      store
        .dispatch(Actions.GET_SALES_ACHIEVEMENTS)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
     });
  }
}

export default AchievementService;
<template>
  <div class="online-checkout-popup">
      <div class="d-flex justify-content-between align-items-center">
        <h2>Online Payment Info</h2>
        <p>
          Quote reference
          <strong>{{ data.invoice.merchant_reference }}</strong>
        </p>
      </div>
      <div class="checkout-block">
        <div class="checkout-top">
          <div class="row g-0">
            <div class="col-lg-10 col-md-12 left-col">
              <div class="checkout-top__block">
                <div
                  class="result-listing__item__block_top_row flex-wrap d-flex align-items-center"
                >
                  <div class="partner-logo">
                    <img :src="data.policy.image" class="img-fluid" />
                  </div>

                  <div class="partner-content">
                    <div class="partner-content_title">
                      <h5 class="mb-0">
                        {{ data.policy.policy_name }}
                      </h5>
                    </div>
                    <div class="partner-content_bottom d-flex flex-wrap">
                      <div class="partner-content_bottom_item">
                        <div class="icon">
                          <!-- <img
                            src="/media/background/car-protection.png"
                            class="img-fluid"
                          /> -->
                          <div class="icon" >
                            <img v-if="isBike" src="media/background/bike-protection.png" class="img-fluid"/>
                            <img v-else src="media/background/car-protection.png" class="img-fluid"/>
                          </div>
                        </div>
                        <div class="title">
                          <div
                            class="text"
                            v-if="data.invoice && data.invoice.car_lead"
                          >
                            {{ data.invoice.car_lead.make }}
                            {{ data.invoice.car_lead.model }}
                            {{ data.invoice.car_lead.model_details }}
                            {{ data.invoice.car_lead.car_year }}
                          </div>
                        </div>
                      </div>

                      <div class="partner-content_bottom_item" v-if="
                                data.invoice.policy_purchase &&
                                data.invoice.policy_purchase.policy_start_date
                              ">
                        <div class="icon">
                          <img
                            src="/media/background/calender.png"
                            class="img-fluid"
                          />
                        </div>
                        <div class="title">
                          <div
                            class="text"
                            v-if="showPolicyPurchaseDate"
                          >
                            (from
                            {{
                              data.invoice.policy_purchase.policy_start_date
                                ? data.invoice.policy_purchase
                                    .policy_start_date_format
                                : "-"
                            }}
                            to
                            {{
                              data.invoice.policy_purchase.policy_end_date
                                ? data.invoice.policy_purchase
                                    .policy_end_date_format
                                : "-"
                            }})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-12 right-col">
              <div class="right-buy-now text-center h-100">
                <div class="right-buy-now-top">
                  <div class="premium-title">
                    <p>Total Premium</p>
                  </div>
                  <div class="price-title">
                    <h4 class="mb-0">
                      {{ data.invoice.original_price ? currency(premium) : '-' }}
                    </h4>
                  </div>
                </div>
              </div>                
              </div>
            </div>
          </div>
          <div class="checkout-middle">
            <div class="partner-content_bottom d-flex flex-wrap">
              <div
                class="partner-content_bottom_item col-sm-3"
                v-if="data.policy.countries && data.policy.countries.length > 0"
              >
                <p>
                  Own Damage Cover
                  <span>{{ data.policy.countries.toString() }}</span>
                </p>
              </div>
              <div
                class="partner-content_bottom_item col-sm-3"
                v-if="
                  data.policy.third_party_damage_limit ||
                  data.policy.third_party_limit
                "
              >
                <p>
                  Third Party Limit
                  <span
                    >Up to
                    {{
                      data.policy.third_party_damage_limit ||
                      data.policy.third_party_limit
                    }}
                  </span>
                </p>
              </div>
              <div class="partner-content_bottom_item col-sm-3">
                <p>
                  Excess Amount
                  <span>{{ data.policy.excess_amount ? currency(data.policy.excess_amount) : '-' }}</span>
                </p>
              </div>
              <div class="partner-content_bottom_item col-sm-3">
                <p class="terms-conditions">
                  Read Terms &amp; Conditions of Insurance Agent
                </p>
              </div>
            </div>
          </div>
        <div class="checkout-middle-footer" v-if="showDetailsSection">
          <div class="result-listing__item__block">
            <div class="result-listing__item__block-bottom">
              <div class="result-listing__item__block-bottom-top">
                <div class="row justify-content-lg-between">
                  <div class="col-lg-3 col-md-3 highlights"  v-if="showHighlights">
                    <div class="listing-title">
                      <h6 class="mb-0">Highlights</h6>
                    </div>
                    <div class="listing-ul">
                      <ul>
                        <li
                          v-for="(highlight, index) in data.policy.car_policy_highlight_title"
                          :key="index"
                          :class="highlight.is_available ? 'check' : 'cross'"
                          >
                          {{ highlight.highlight }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 road-assistant" v-if="showRoadSideAssistance">
                    <div class="listing-title">
                      <h6 class="mb-0">Road Side Assistance</h6>
                    </div>
                    <div class="listing-ul">
                      <ul>
                        <li
                        v-for="(roadside, index) in data.policy
                              .roadside_assistance"
                          :key="index"
                          :class="roadside.is_available ? 'check' : 'cross'"
                          >
                          {{ roadside.title }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 extra-add-col" v-if="showAddons">
                    <div class="listing-title">
                      <h6 class="mb-0">Extra Addons</h6>
                    </div>

                    <div
                        class="extra-addons d-flex flex-wrap align-items-center"
                        v-for="(addon, index) in data.addOns"
                        :key="index"
                      >
                      <div class="addons-checkbox">
                            <input
                              class="styled-checkbox"
                              id="replacement"
                              type="checkbox"
                              value="value1"
                              checked
                            />
                            <label> </label>
                          </div>
                          <div class="addons-text">
                            <div>
                              {{ addon.add_on }}
                              <span>
                                <strong>
                                  {{ addon.amount_original_price ? currency(addon.amount_original_price) : '-' }}</strong
                                >
                                ({{ addon.details }})
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 special-feactures" v-if="showSpecialFeatures">
                      <div class="listing-title">
                        <h6 class="mb-0">Special Features</h6>
                      </div>
                      <div class="listing-p">
                        <div class="desc-p">
                          <p v-html="data.policy.special_features"></p>
                        </div>
                      </div>
                      </div>

                      <div class="col-lg-3 col-md-3 highlights"  v-if="!showHighlights"></div>
                    <div class="col-lg-3 col-md-3 road-assistant"  v-if="!showRoadSideAssistance"></div>
                    <div class="col-lg-3 col-md-3 extra-add-col"  v-if="!showAddons"></div>
                    <div class="col-lg-3 col-md-3 extra-add-col"  v-if="!showSpecialFeatures"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="checkout-bottom checkout-bottom-container">
          <div class="checkout-bottom_block">
            <div class="row">
              <div class="col-md-6 left-col">
                <div class="checkout-left-row-block">
                  <div class="checkout-left-row summary">
                    <div class="summary-block">
                      <div class="summary-block__table">
                        <div class="summary-block__table--item">
                          <div class="left">Discount code</div>
                          <div class="right">
                            {{
                              data.invoice && data.invoice.promo_code
                                ? data.invoice.promo_code.promotion_code
                                : "-"
                            }}
                          </div>
                        </div>
                        <div class="summary-block__table--item">
                          <div class="left">Discount Amount (AED)</div>
                          <div class="right">
                            {{ data.invoice.discount_amount > 0 ? currency(data.invoice.discount_amount) : '-' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 right-col">
                <div class="summary">
                  <div class="inner-title">
                    <h5 class="mb-0">Summary</h5>
                  </div>

                  <div class="summary-block">
                    <div class="summary-block__table">
                      <div class="summary-block__table--item">
                        <div class="left">Premium</div>
                        <div class="right">
                          {{ data.invoice.original_price ? currency(premium) : '-' }}
                        </div>
                      </div>
                      <div
                        class="summary-block__table--item"
                        v-if="data.invoice.policy_purchase_addon && 
                              data.invoice.policy_purchase_addon
                              .personal_accident_driver_original_price > 0"
                      >
                        <div class="left">Personal Accident Driver</div>
                        <div class="right">
                          {{ currency(data.invoice.policy_purchase_addon.personal_accident_driver_original_price)}}
                        </div>
                      </div>
                      <div
                        class="summary-block__table--item"
                        v-if="data.invoice.policy_purchase_addon && 
                              data.invoice.policy_purchase_addon
                              .personal_accident_passenger_original_price > 0"
                      >
                        <div class="left">Personal Accident Passenger</div>
                        <div class="right">
                          {{ currency(data.invoice.policy_purchase_addon.personal_accident_passenger_original_price)}}
                        </div>
                      </div>
                      <template v-if="data.addOns && data.addOns.length > 0">
                        <div
                          class="summary-block__table--item"
                          v-for="(addon, index) in data.addOns"
                          :key="index"
                        >
                          <div class="left">
                            {{ addon.add_on }}
                            <span style="font-weight: bold">
                              ( {{ addon.details }} )
                            </span>
                          </div>
                          <div class="right">
                            {{ currency(addon.amount_original_price)}}
                          </div>
                        </div>
                      </template>

                      <div class="summary-block__table--item">
                        <div class="left">VAT 5 %</div>
                        <div class="right">{{ data.invoice.vat ? currency(data.invoice.vat) : '-'}}</div>
                      </div>
                      <div
                        class="summary-block__table--item"
                        v-if="data.invoice.discount_amount > 0"
                      >
                        <div class="left">Discount Amount</div>
                        <div class="right">
                          {{ currency(data.invoice.discount_amount) }}
                        </div>
                      </div>
                      <div
                        v-if="data.invoice && data.invoice.payment_type_text == 'COD'"
                        class="summary-block__table--item">
                        <div class="left">Cash Payment Service Charge</div>
                        <div class="right">
                          {{ currency(data.invoice.admin_extra_charges) }}
                        </div>
                      </div>
                      <div class="summary-block__table--item mb-0">
                        <div class="left">Amount Payable</div>
                        <div class="right big-bold">
                          {{ currency(totalAmount) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script setup>

import { defineProps, computed } from 'vue'

const props = defineProps({
      data: {
          type: Object,
          required: true
      }
  })


  const currency = (e) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AED"
    });

    return formatter.format(e);
  };


const totalAmount = computed(() => {
  return parseFloat(props.data.invoice.sale_price) + parseFloat(props.data.invoice.admin_extra_charges)
})

const premium = computed(() => {
  /**
   * Commented since the Personal Accident Driver and Personal Accident Passenger prices are not coming on addons 
   * and the orginal price is included those values the premium is showing wrong. and returning the value from quotes
   * eg : premium is 1700 and Personal Accident Driver, Personal Accident Passenger is 120 each the orginal_price will be 1940
   * totalAddons will be 0;
   *
   * return parseFloat(props.data.invoice.original_price) - parseFloat(props.data.invoice.original_price_vat) - parseFloat(props.data.invoice.totalAddons)
   */
   let premium = props.data.invoice.car_lead_quote.quote_price;
   if(!premium){
      premium = parseFloat(props.data.invoice.original_price) - parseFloat(props.data.invoice.original_price_vat) - parseFloat(props.data.invoice.totalAddons);
   }
    return parseFloat(premium);
})

const showPolicyPurchaseDate = computed(()=>{
      return (props.data.invoice.policy_purchase && props.data.invoice.policy_purchase.policy_start_date);
  })
  const showDetailsSection = computed(()=>{
      return (props.data.policy && (props.data.policy.car_policy_highlight_title || props.data.policy.roadside_assistance || (props.data.addOns && props.data.addOns.length > 0)));
    })

    
    const showHighlights = computed(()=>{
      return props.data.policy && props.data.policy.car_policy_highlight_title;
    })

    const showRoadSideAssistance = computed(()=>{
      return props.data.policy && props.data.policy.roadside_assistance;
    })


    const showAddons = computed(()=>{
      return props.data.addOns && props.data.addOns.length > 0;
    })

    const showSpecialFeatures = computed(()=>{
      return props.data && props.data.policy.special_features;
    })

</script>

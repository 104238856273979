
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ICustomers from "@/models/Customers";
import ApiService from "@/core/services/ApiService";
import ILeads from "@/models/car/ILeads";

import axios from "axios";
export interface EmailInfo {
    emailTemplateList: IEmailTemplatesModel;
    emailUrl: EmailUrlInfo;
    emailList:IEmailList;
    errors: Array<string>;
    
}
export interface IEmailTemplatesModel {
    value: number;
    label: string;
    id: number;
    subject: string;
}
export interface EmailUrlInfo {

    getEmailTemplate: string;
    sendEmail: string;
    getEmailList:string;
    sendEmailItTeamUrl: string;
    sendEmailPaymentUrl: string;
}
export interface IEmailList {
    id: number;
    subject: string;
    lead_id?: any;
    customer_id: number;
    insured_id?: any;
    delivered: number;
    opened?: any;
    clicked?: any;
    created_at: string;
    updated_at?: any;
}

@Module
export default class EmailModule extends VuexModule implements EmailInfo {
    emailTemplateList = {} as IEmailTemplatesModel;
    emailUrl = {} as EmailUrlInfo;
    emailList = {} as IEmailList;
    errors = [];
    QLerrors: any;

    @Action
    [Actions.GET_EMAIL_TEMPLATE]() {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.emailUrl.getEmailTemplate = '/skye/emails/list-email-templates';
            ApiService.post(this.emailUrl.getEmailTemplate, {})
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_EMAIL_TEMPLATE, data.data.templates);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                // .catch(({ response }) => {
                //     this.context.commit(Mutations.SET_EMAIL_ERROR, response.data.errors);
                //     reject(response);
                // });
        });
    }
    @Action
    [Actions.SEND_EMAIL](payload) {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.emailUrl.sendEmail = '/skye/emails/send-email';
            ApiService.post(this.emailUrl.sendEmail, payload)
                .then(({ data }) => {
                    // this.context.commit(Mutations.SET_EMAIL_RESPONSE, data.data.templates);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_EMAIL_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_EMAIL_LIST](payload) {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.emailUrl.getEmailList = '/skye/emails/list-emails';
            ApiService.post(this.emailUrl.getEmailList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_EMAIL_LIST, data.data.emails);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_EMAIL_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }


    @Action
    [Actions.SEND_EMAIL_IT_TEAM](payload) {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.emailUrl.sendEmailItTeamUrl = '/skye/emails/send-email-invoice-issue';
            ApiService.post(this.emailUrl.sendEmailItTeamUrl, payload)
                .then(({ data }) => {
                    // this.context.commit(Mutations.SET_EMAIL_RESPONSE, data.data.templates);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_EMAIL_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.SEND_EMAIL_PAYMENT_CUSTOMER](payload) {
        // this.context.commit(Mutations.SET_DOM_STATUS, false);
        return new Promise<any>((resolve, reject) => {
            this.emailUrl.sendEmailPaymentUrl = '/skye/emails/send-email-payment-customer';
            ApiService.post(this.emailUrl.sendEmailPaymentUrl, payload)
                .then(({ data }) => {
                    // this.context.commit(Mutations.SET_EMAIL_RESPONSE, data.data.templates);
                    
                    resolve(data);
                    // this.context.commit(Mutations.SET_DOM_STATUS, true);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_EMAIL_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    
    @Mutation
    [Mutations.SET_EMAIL_ERROR](error) {
        const errors = [] as any;
        for (const key in error) {

            errors.push(error[key][0]);

        }
        this.errors = errors;
    }
    @Mutation
    [Mutations.SET_EMAIL_LIST](data) {
        this.emailList = data;
    }
    @Mutation
    [Mutations.SET_EMAIL_TEMPLATE](data) {
        this.emailTemplateList = data;
    }
    get getEmailTemplateList(): IEmailTemplatesModel {
        return this.emailTemplateList;
    }
    get getCustomerEmailList(): IEmailList {
        return this.emailList;
    }
}


import { defineComponent, ref, watchEffect } from 'vue'

export default defineComponent({
    props: {
        modelValue: {
            type: Array,
            required: true,
            // default: [],
        },
        clearable: {
          type: Boolean,
          required: false,
          default: true
        }
    },
    setup(_, {emit}) {
        const myDate:any = ref([])

        watchEffect(() => {
            myDate.value = _.modelValue
        })

        const shortcuts = ref([
        {
          text: "Today",
          value: (() => {
            const end = new Date();
            const start = new Date();
            return [start, end];
          })()
        },
        {
          text: "Yesterday",
          value: (() => {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          })()
        },
        {
          text: "Last 7 Days",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last 30 Days",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "This Month",
          value: (() => {
            const end = new Date();
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            return [start, end];
          })()
        },
        {
          text: "Last Month",
          value: (() => {
            const end = new Date();
            end.setDate(0);
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ])

        const updateValue = () => {
            emit('update:modelValue', myDate.value)
            emit('change', myDate.value)
        }
      return {
        myDate,
        shortcuts,
        updateValue
      }
    },
})

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import IPaginationInfo from "@/models/IPaginationInfo";


export interface SalesInfo {
    salesUrl: SalesUrlInfo;
    salesTargetList: SalesTargetList;
    paginationObj: IPaginationInfo;
}

export interface SalesUrlInfo {
    getSalesTargetList: string;
    saveSalesTarget: string;
}

export interface SalesTargetList {
    id: number;
    name: string;
    status: number;
    user_status: string;
    sales: Sales
}

export interface Sales {
    id: number;
    sales_agent_id: number;
    no_of_deals: number;
    year: number;
    month: number;
    amount: string;
    created_at: any;
    updated_at: any;
}

export interface LeadsInfo {
    leadsUrl: LeadsUrlInfo;
    leadsTargetList: LeadsTargetList;
    paginationObj: IPaginationInfo;
}

export interface LeadsUrlInfo {
    saveLeadsTarget: string;
    getLeadsTargetList: string
}

export interface LeadsTargetList {
    id: number;
    name: string;
    status: number;
    user_status: string;
    sales: Leads
}

export interface Leads {
    id: number;
    marketing_user_id: number;
    no_of_deals: number;
    year: number;
    month: number;
    amount: string;
    created_at: any;
    updated_at: any;
}

@Module
export default class SalesModule extends VuexModule implements SalesInfo, LeadsInfo {
    salesUrl= {} as SalesUrlInfo;
    leadsUrl= {} as LeadsUrlInfo;
    salesTargetList = {} as SalesTargetList;
    leadsTargetList = {} as LeadsTargetList;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    errors = [];

    @Action
    [Actions.GET_SALES_TARGET_LIST](payload) {
        return new Promise<any>((resolve, reject) => {
            this.salesUrl.getSalesTargetList = "skye/sales/list";
            ApiService.post(this.salesUrl.getSalesTargetList, payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_SALES_TARGET_LIST, data);
                    // 
                    resolve(data);
                })
                .catch(({ response })=>{
                   this.context.commit(Mutations.SET_SALES_ERROR, response.data);
                   reject(response);
                });
        });
    }

    @Action
    [Actions.SAVE_SALES_TARGET](payload) {
        return new Promise<any>((resolve, reject) => {
            this.salesUrl.saveSalesTarget = "skye/sales/add-sales-target";
            ApiService.post(this.salesUrl.saveSalesTarget, payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_SALES_TARGET_LIST, data);
                    // 
                    resolve(data);
                })
                .catch(({ response })=>{
                    this.context.commit(Mutations.SET_SALES_ERROR, response.data);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_LEADS_TARGET_LIST](payload) {
        return new Promise<any>((resolve, reject) => {
            this.leadsUrl.getLeadsTargetList = "skye/marketing/list";
            ApiService.post(this.leadsUrl.getLeadsTargetList, payload)
                .then(({data}) => {
                    console.log(data)
                    this.context.commit(Mutations.SET_LEADS_TARGET_LIST, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_LEADS_TARGET_ERROR, response.data);
                    reject(response);
                });
        })
    }

    @Action
    [Actions.SAVE_LEADS_TARGET](payload) {
        return new Promise<any>((resolve, reject) => {
            this.leadsUrl.saveLeadsTarget = "skye/marketing/add-marketing-target";
            ApiService.post(this.leadsUrl.saveLeadsTarget,payload)
                .then(({ data }) => {
                    console.log(data)
                    this.context.commit(Mutations.SET_LEADS_TARGET_LIST, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_LEADS_TARGET_ERROR, response.data);
                    reject(response);
                });
        });
    }

    @Mutation
    [Mutations.SET_SALES_TARGET_LIST](payload) {
    //
    this.salesTargetList = payload.data.sales;
  }

    @Mutation
    [Mutations.SET_LEADS_TARGET_LIST](payload) {
            this.leadsTargetList = payload.data.marketing_targets;
      }

    @Mutation
    [Mutations.SET_LEADS_TARGET_ERROR](error) {
        //
        for (const key in error) {
            this.errors = error[key];
        }
    }

    @Mutation
    [Mutations.SET_LEADS_ERROR](error) {
        //
        for (const key in error) {
            this.errors = error[key];
        }
    }
  
  get getSalesTarget(): SalesTargetList {
    return this.salesTargetList;
  }

  get getLeadsTarget(): LeadsTargetList {
        return this.leadsTargetList;
  }
}
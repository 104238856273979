import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-date-picker" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "datepicker-icon" }
const _hoisted_4 = ["min", "max", "disabled"]
const _hoisted_5 = { class: "svg-icon svg-icon-1 icon-cal bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-control",
      type: "text",
      placeholder: "DD/MM/YYYY HH:SS",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstDate) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args))),
      onKeydown: [
        _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('goNext')), ["prevent","exact"]), ["tab"])),
        _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('goBack')), ["prevent","shift"]), ["tab"]))
      ],
      maxlength: "16",
      disabled: _ctx.disabled,
      ref: "focus"
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.firstDate]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        class: "form-check-input",
        type: "date",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.firstDate) = $event)),
        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.setDateFormat && _ctx.setDateFormat(...args))),
        onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.setDateFormatInput && _ctx.setDateFormatInput(...args))),
        min: _ctx.min,
        max: _ctx.max,
        disabled: _ctx.disabled
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.firstDate]
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_inline_svg, { src: "media/background/calendar-blue.svg" })
      ])
    ])
  ]))
}
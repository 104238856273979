import router from "@/router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed, resolveComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class LeadService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    LeadService.vueInstance = app;
  }

  public static getLeadActivityLogs(payload) {
    store.dispatch(Actions.GET_LEAD_ACTIVITY_LOG, payload)
      .then((data) => {

        // router.push({ name: "customer-list" });
      })
      .catch((error) => {
        // 
      })
  }

  public static getLeadStatusLogs(payload) {
    store.dispatch(Actions.GET_LEAD_STATUS_LOG, payload)
      .then((data) => {

        // router.push({ name: "customer-list" });
      })
      .catch((error) => {
        // 
      })
  }

  public static checkLeadMerge(payload) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.CHECK_LEAD_MERGE, payload)
        .then((data) => {
          resolve(data);
          // router.push({ name: "customer-list" });
        })
        .catch((error) => {
          // 
        })
    })
  }
  public static mergeLead(payload) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.MERGE_LEAD, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          // 
        })
    });


  }

  public static updateClosedLead(payload) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.UPDATE_CLOSED_LEAD, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });


  }

  public static getCustomerLeadsForMerge(payload) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.GET_CUSTOMER_LEADS_FOR_MERGE, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });


  }



}

export default LeadService;

import router from "@/router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class NotesService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    NotesService.vueInstance = app;
  }

  public static getLeadNotesList(payload) {
    store.dispatch(Actions.PULL_NOTES, payload)
      .then((data) => {

        // router.push({ name: "customer-list" });
      })
      .catch((error) => {
        // 
      })
  }
  public static addLeadNotes(payload) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.ADD_NOTES, payload)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject();
          // 
        })
    });

  }



}

export default NotesService;

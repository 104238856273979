
import { computed, defineComponent, ref, onMounted, watchEffect } from "vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import $ from 'jquery';
import MasterService from "@/core/services/car/MasterService";
import { customFunction } from "@/store/stateless/store";
import LocalStorageServices from "@/core/services/LocalStorageServices";

export default defineComponent({
  name: "view-bank-details-modal",
  //   components: { BankDetailsModal },
  setup() {
    const store = useStore();

    onMounted(() => {
      if(Object.keys(user.value).length > 0) {
        // MasterService.getBankDetails();
      }
    });
    const bankDetails = computed(() => {
      return store.getters.getBankDetailsInformation;
    });
    const isViewed = computed(() => {
      return store.getters.getViewBankDetails;
    });
    const setViewBankDetails = () => {
      store.commit(Mutations.SET_VIEW_BANK_DETAILS, false);
    };

    const user = computed(() => {
      const userObj = LocalStorageServices.getUser();
      if (userObj == null) {
        return {};
      }
      return JSON.parse(userObj);
    });

    watchEffect(() => { 
      if(isViewed.value)
      {
        MasterService.getBankDetails();
      }
    });

    return {
      user,
      isViewed,
      setViewBankDetails,
      bankDetails,
      customFunction
    };
  },
});

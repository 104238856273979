import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";

// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class EmailService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        EmailService.vueInstance = app;
    }

    public static getEmailTemplate() {
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.GET_EMAIL_TEMPLATE)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }
    public static sendEmail(payload) {
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.SEND_EMAIL,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }
    public static getEmailList(payload) {
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.GET_EMAIL_LIST,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { console.log(error); });
        });

    }

    public static sendEmailItTeam(payload) {
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.SEND_EMAIL_IT_TEAM,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { reject(error); });
        });

    }

    public static sendEmailPaymentCustomer(payload) {
        return new Promise<void>((resolve, reject) => {
            store.dispatch(Actions.SEND_EMAIL_PAYMENT_CUSTOMER,payload)
                .then((data) => {
                    
                    resolve(data);
                })
                .catch((error) => { reject(error); });
        });

    }

}

export default EmailService;


import { defineComponent, nextTick, ref, watch, onMounted } from 'vue'

export default defineComponent({
    name: 'custom-emirates-id-input',
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {

        const emiratesID = ref("")

        watch(emiratesID, (value) => {
            if(!props.disabled)
            {
                const result = value
                    .replace(/^(\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d-\d\d\d\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/[^\d-]/g, "");
                nextTick(() => (emiratesID.value = result));
            }    
        
        });
        onMounted(() => {
            if(props.disabled ||  props.modelValue)
            {
                emiratesID.value = props.modelValue;
            }
           
        });

        const autoMask = () => {
            let all = ''
            if(emiratesID.value.length === 15) {
                const str = emiratesID.value
                const first = str.slice(0, 3)
                const second = str.slice(3, 7)
                const third = str.slice(7, 14)
                const fourth = str.slice(14, 15)
                all = `${first}-${second}-${third}-${fourth}`
            }
            const myArray = emiratesID.value.split('-')
            if(emiratesID.value.length === 18 && myArray.length === 4) {
                all = emiratesID.value
            }
            emiratesID.value = all
            context.emit("update:modelValue", all)
        }
      

        const updateValue = () => {
            context.emit("update:modelValue", emiratesID.value)
        }

        const focus = ref()

        const isFocus = () => {
            focus.value.focus()
        }
  

        return {
            isFocus,
            focus,
            autoMask,
            emiratesID,
            updateValue,
        }
    },
})

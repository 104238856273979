import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

@Module
export default class User extends VuexModule {
    salesAgents = []
    addUser = {
        id: 0,
        user_id: 0,
        agent_count: 0,
        assign_ar_leads:0,
        assign_car:0,
        assign_en_leads:0,
        assign_health:0,
        assign_misc:0,
        email:"",
        is_all_deals: -1,
        is_both:0,
        is_archived: 0,
        is_llr: 0,
        is_new:0,
        is_renewal:0,
        is_round_robin: -1,
        is_underwriter:0,
        misc_insurance_types: [],
        name:"",
        office_mobile_number:"",
        official_email:"",
        other_mobile_number:"",
        password:"",
        password_confirmation:"",
        policy_agent_id:0,
        role_id:0,
        roles: [],
        skip_round:0,
        status:1,
        team_leader:0,
        agent_type: 0,
        assign_lead: 0,
        assign_language: [],
        is_edit: false
    }

    // Actions
    @Action
    async ['GET_SALES_AGENTS'](payload) {
        try {
            const response = await ApiService.post('/skye/agent-list-for-dom-new', payload)
            this.context.commit('SET_SALES_AGENTS', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    // Mutations
    @Mutation
    ['SET_SALES_AGENTS']({ agents }) {
        this.salesAgents = agents
    }

    @Mutation
    ['SET_USER'](user) {
        this.addUser = user
    }

    get getUserData() {
        return (data) => {
            let agent_type = 0
            let assign_lead = 1
            let assign_ar_leads = 0
            let assign_car = 0
            let assign_en_leads = 0
            let assign_health = 0
            let assign_misc = 0
            let is_all_deals = -1
            let is_both = 0
            let is_archived = 0
            let is_llr = 0
            let is_new = 0
            let is_renewal = 0
            let is_round_robin = -1
            let misc_insurance_types = []
            let assign_ml_leads = 0

            const assign_language:any = []
            if(data.round_robin_setting) {
                if(data.round_robin_setting.is_new == 1) agent_type = 1
                if(data.round_robin_setting.is_renewal == 1) agent_type = 2
                if(data.round_robin_setting.is_both == 3) agent_type = 3
                if(data.round_robin_setting.assign_health == 1) assign_lead = 2
                if(data.round_robin_setting.assign_misc == 1) assign_lead = 3
                if(data.round_robin_setting.assign_ml_leads == 1) assign_language.push(3)
                if(data.round_robin_setting.assign_ar_leads == 1) assign_language.push(2)
                if(data.round_robin_setting.assign_en_leads == 1) assign_language.push(1)

                assign_ml_leads = data.round_robin_setting.assign_ml_leads
                assign_ar_leads = data.round_robin_setting.assign_ar_leads
                assign_car = data.round_robin_setting.assign_car
                assign_en_leads = data.round_robin_setting.assign_en_leads
                assign_health = data.round_robin_setting.assign_health
                assign_misc = data.round_robin_setting.assign_misc
                is_all_deals = data.round_robin_setting.is_all_deals
                is_both = data.round_robin_setting.is_both
                is_archived = data.round_robin_setting.is_archived
                is_llr = data.round_robin_setting.is_llr
                is_new = data.round_robin_setting.is_new
                is_renewal = data.round_robin_setting.is_renewal
                is_round_robin = data.round_robin_setting.is_round_robin
                misc_insurance_types = data.round_robin_setting.user_misc_settings.map(x => x.misc_insurance_type)
            }
            

            return {
                id: data.id,
                user_id: data.id,
                agent_count: data.agentCount ? data.agentCount : 0,
                assign_ar_leads,
                assign_car,
                assign_en_leads,
                assign_health,
                assign_misc,
                email: data.email,
                is_all_deals,
                is_both,
                is_archived,
                is_llr,
                is_new,
                is_renewal,
                is_round_robin,
                is_underwriter: data.is_underwriter,
                misc_insurance_types,
                name: data.name,
                office_mobile_number: data.office_mobile_number,
                official_email: data.official_email,
                other_mobile_number: data.other_mobile_number,
                password: '',
                password_confirmation: '',
                policy_agent_id: data.policy_agent_id,
                role_id: data.role_id,
                roles: data.roles,
                skip_round: data.skip_round,
                status: data.status,
                team_leader: data.team_leader_id,
                agent_type,
                assign_lead,
                assign_language,
                agents: [],
                show_archived: data.status == 0,
                is_edit: true,
            }
        }
    }
}

import moment, { isDate } from "moment";
import { defineComponent, nextTick, onMounted, ref, watch, watchEffect } from "vue";
import browser from '@/browser.js'
import { isValidDate } from "@fullcalendar/common";

export default defineComponent({
  name: "custom-date-picker",
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: "",
    },
    defaultTime: {
      type: String,
      required: false,
      default: "",
    },
    max: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d\/\d\d\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\/\d\d\/\d\d\d\d \d\d)(\d+)$/g, "$1:$2")
        .replace(/^(\d\d\/\d\d\/\d\d\d\d \d\d:\d\d)(\d+)$/g, "");
      nextTick(() => (firstDate.value = result));
    });

    watchEffect(() => {
        const onDate = new Date(props.modelValue)
        let myDate = moment(onDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
        if(myDate !== 'Invalid date') {
            firstDate.value = moment(myDate).format(
                "DD/MM/YYYY HH:mm"
            );
        }
    })

    const updateValue = () => {

      if (firstDate.value.length == 16) {
        let myDate = moment(firstDate.value, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
        context.emit("update:modelValue", myDate);
      }

    };


    const setDateFormat = () => {
        // if(props.defaultTime) 
      context.emit(
        "update:modelValue",
        moment(`${firstDate.value} ${props.defaultTime ? props.defaultTime : '09:00'}`).format(`YYYY-MM-DD HH:mm`)
      );
      let setDate = moment(`${firstDate.value} ${props.defaultTime ? props.defaultTime : '09:00'}`, `YYYY-MM-DD HH:mm`).format("DD/MM/YYYY HH:mm");
      firstDate.value = setDate;
    };

    const setDateFormatInput = () => {
      changeValue();
    };

    const changeValue = () => {
      let myDate = moment(firstDate.value, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
      const currentDate =
        props.max != ""
          ? props.max
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      const minDate = moment(props.min, "YYYY-MM-DD").format("DD/MM/YYYY");
      const maxDate = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (firstDate.value.length != 16) {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (props.modelValue == "Invalid date") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (myDate == "NaN-NaN-NaN") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }


      if (firstDate.value.length == 16 && myDate != "Invalid date") {
        if (
          props.max != "" &&
          maxDate > moment(currentDate).format("YYYY-MM-DD")
        ) {
            // alert('if')
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else if (
          props.min != "" &&
          moment(myDate).format('YYYY-MM-DD') < moment(props.min).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else {
          context.emit("update:modelValue", myDate);
        }
      } else {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      context.emit("vcBlur")
    };

    const focus = ref()

    const isFocus = () => {
      focus.value.focus()
    }

    return {
      focus,
      isFocus,
      firstDate,
      updateValue,
      changeValue,
      setDateFormat,
      setDateFormatInput,
    };
  },
});

import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    class: "form-control",
    placeholder: "123-1234-1234567-1",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emiratesID) = $event)),
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.autoMask && _ctx.autoMask(...args))),
    disabled: _ctx.disabled,
    maxlength: "18",
    ref: "focus"
  }, null, 40, _hoisted_1)), [
    [_vModelText, _ctx.emiratesID]
  ])
}
/**
 * Sales Dashboard ( Achievement ) Module.
 * 
 * Learning Note: Register every new module in store/index.ts if it is not registered.
 */

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

/**
 * Importing some related interfaces.
 */


/**
 * Interface for API's payloads (bodyParams).
 */
interface ISalesAchievements {
    totalDeals: number,
    totalRevenue: number,
    totalLeads: number,
    totalDealsMonthly: number,
    totalRevenueMonthly: number,
    totalSalesTargetMonthly: number,
    totalRevenueTargetMonthly: number,
    totalRefundAmountMonthly: number,
    totalCancelledAmountMonthly: number,
    user_stats_first: IUserStats,
    user_stats: IUserStats[],
}

interface IUserStats {
    name : string,
    first_name : string,
    last_name : string,
    image_url : string,
    box_css_class : string,
    todays_deals_count : number,
    monthly_deals_count : number,
    todays_revenue_sum : number,
    monthly_revenue_sum : number,
    todays_leads_count : number,
    monthly_leads_count : number,
    monthly_sales_target : number,
    monthly_revenue_target : any,
    monthly_refund_amount : number,
    monthly_cancelled_amount : number,
}

/**
 * Starting Module
 */
@Module
export default class ReportModule extends VuexModule {
    errors = [];

    salesAchievements = {} as ISalesAchievements;

    // Action - GET SALES ACHIEVEMENTS
    @Action
    [Actions.GET_SALES_ACHIEVEMENTS]() {
        return new Promise<void>((resolve, reject) => {

            const url = "/skye/summary/sales-achievements";

            ApiService.post_get(url)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ERROR, []);
                    this.context.commit(Mutations.SET_SALES_ACHIEVEMENTS, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response);
                    reject(response);
                });
        });
    }
    
    /**
     * MUTATION
     * 
     * The only way to change the state in a Vuex store is by committing a mutation. 
     * Vuex mutations are very similar to events: each mutation has a string type 
     * and a handler. The handler function is where we perform actual state modifications, 
     * and it will receive the state as the first argument.
     */
    @Mutation
    [Mutations.SET_SALES_ACHIEVEMENTS](payload) {
        this.salesAchievements = payload;
    }

    /**
     * GETTERS
     * 
     * Getter used to fetch variable value.
     */
    get getSalesAchievementsRecord() {
        return this.salesAchievements;
    }

}

import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Breadcrumb {
  title: string;
  pageBreadcrumbPath: Array<string>;
}

interface StoreInfo {
  breadcrumbs: Breadcrumb;
}

@Module
export default class BreadcrumbsModule extends VuexModule implements StoreInfo {
  breadcrumbs = {} as Breadcrumb;
  quickLink = "";
  isBankDetailsViewed = false;
  isQuickLeadModalViewed = false;
  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getBreadcrumbs(): Breadcrumb {
    return this.breadcrumbs;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get pageBreadcrumbPath(): Array<string> {
    return this.breadcrumbs.pageBreadcrumbPath;
  }

  /**
   * Get current page title
   * @returns string
   */
  get pageTitle(): string {
    return this.breadcrumbs.title;
  }
  /**
   * Get Quick Link Navigator current page
   * @returns object
   */
  get getQuickLinkTab(): string {
    return this.quickLink;
  }
  /**
   * Get is bank details viewed
   * @returns object
   */
  get getViewBankDetails() {
    return this.isBankDetailsViewed;
  }
  /**
   * Get is quick lead viewed
   * @returns object
   */
  get getViewQuickLead() {
    return this.isQuickLeadModalViewed;
  }
  @Mutation
  [Mutations.SET_BREADCRUMB_MUTATION](payload) {
    this.breadcrumbs = {
      title: '',
      pageBreadcrumbPath: []
    };
    this.breadcrumbs = payload;
  }
  @Mutation
  [Mutations.SET_QUICK_LINK_NAVIGATION](payload) {
    this.quickLink = payload;
  }
  @Mutation
  [Mutations.SET_VIEW_BANK_DETAILS](payload) {
    this.isBankDetailsViewed = payload;
  }
  @Mutation
  [Mutations.SET_VIEW_QUICK_LEAD](payload) {
    this.isQuickLeadModalViewed = payload;
  }
  @Action
  [Actions.SET_BREADCRUMB_ACTION](payload) {
    this.context.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
  }
}
